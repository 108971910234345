import React from "react";
import { Container, Row, Col } from "reactstrap";
import Card from "reactstrap/lib/Card";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          // background: `url(${require("assets/images/dark-abstract-with-flowing-waves copy-NEW.png")}), url(${require("assets/images/background8.png")})`,
          background: `url(${require("assets/images/shanghai-aerial-sunset.jpg")})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
          paddingTop: "8rem",
          overflow: "hidden",
        }}
        className="section py-3"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            <div className="overlay-primary" style={{ backgroundColor: "rgba(5,27,21, 0.6)" }}></div>
            <Col md={6} className={size ? "border-right" : "border-bottom"}>
              <h1
                className="m-0 my-4 text-g"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                Event
                <br />
                Summary
              </h1>
              <p className="text-400 text-white">
                PIF’s Giga projects steadily highlight the Kingdom’s vision towards economic,
                social, and cultural diversification and form the Crown Jewels of the
                <span className="text-700 text-i">Vision 2030.</span>
                <br />
                <br />
                The key projects for Housing, Urban and Tourism such as{" "}
                <span className="text-700 text-f">
                  NEOM- The Line, TROJENA, Oxagon, The New Murabba, Qiddiya, Diriyah Gate
                  Development Authority, Red Sea Global, ROSHN, Royal Commission of AlUla, Rua Al
                  Madinah and Smart Cities lead by Riyadh Municipality, Royal Commission of Riyadh
                  City, etc.
                </span>{" "}
                are not only planned but are under way that are set to transform how we construct/
                maintain cities and how people live & interact - to how tourism is perceived. These
                revolutionary projects have complex teams involved globally that plan, design, view
                developments and maintain on a real time basis – via cutting edge systems that
                include agile Data management, AI and Digital Twins. <br />
                <br />
                With the success of our DIGITAL TWIN conferences in Dubai, Riyadh and London and
                Auckland since 2022, Saifee Events is happy to continue to raise the dialogue in
                Riyadh. The{" "}
                <span className="text-700 text-f">4th Annual KINGDOM DIGITAL TWIN 2025</span>{" "}
                conference will explore the roadmap of a Digital Twin system, its impact on each
                stage of the project and why should we implement it in the Kingdom of Saudi Arabia.
              </p>
            </Col>
            <Col md={6} className="pr-0">
              <h1
                className="m-0 my-4 text-g"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                Conference
                <br />
                Format
              </h1>

              <div className="container-fluid row justify-content-center">
                {content.map((c, i) => (
                  <Col xs={12}>
                    <Card
                      style={{
                        background: c.color,
                        minHeight: size ? "80px" : "none",
                        maxWidth: "400px",
                      }}
                    >
                      <div className="d-flex p-2  ">
                        <div className="px-2 align-self-center" style={{ maxWidth: "70px" }}>
                          <img
                            src={require(`assets/icons/${c.image}`)}
                            // style={{ width: "70px" }}
                            width="100%"
                            alt="about"
                            className=""
                          />
                        </div>
                        <p className="text-400 py-2 pl-3 text-white align-self-center">{c.title}</p>
                      </div>
                    </Card>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;

const content = [
  {
    title: "VIP KEYNOTE SPEECHES",
    image: "14.png",
    color: "#88C4C4",
  },
  {
    title: "INTERNATIONAL CASE STUDIES AND PROOF OF CONCEPTS",
    image: "12.png",
    color: "#009f45",
  },
  {
    title: "THOUGHT-PROVOKING PANEL DISCUSSIONS",
    image: "42.png",
    color: "#88C4C4",
  },
  {
    title: "TECHNICAL WORKSHOPS",
    image: "41.png",
    color: "#009f45",
  },
  {
    title: "EXHIBITION & NETWORKING ",
    image: "43.png",
    color: "#88C4C4",
  },
];
